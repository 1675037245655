<div class="container">
  <div class="row login-wrapper">
    <form class="login-form d-flex flex-column" id="loginForm" [formGroup]="loginForm" (ngSubmit)="loginSubmit()">
      <img src="assets/img/logo.svg" alt="Saltbox">
      <h3>Login to Saltbox Solutions</h3>
      <div class="form-group">
        <input class="form-control" type="text" id="email" formControlName="email" placeholder="Email">
      </div>
      <div class="form-group">
        <input class="form-control" type="password" id="password" formControlName="password" placeholder="Password">
      </div>
      <div class="d-flex justify-content-center">
        <button type="submit" class="btn">Log in</button>
      </div>
      <div class="links">
        <p>Forgot your password? <a [routerLink]="['/register']">Reset it here</a></p>
        <p>Not yet registered? <a [routerLink]="['/register']">Sign up</a></p>
      </div>
    </form>
  </div>
</div>

