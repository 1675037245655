<div class="e-force">
  <div class="page-header d-flex justify-content-between">
    <div class="heading"><span class="hand-drawn-underline">eForce</span><img src="assets/icons/info.svg" alt="Info"/></div>
    <div class="book-btn d-flex flex-column justify-content-between align-items-center"><img src="assets/icons/people.svg" alt="Book"/><span>BOOK</span></div>
  </div>
  <div class="tab-selector d-flex justify-content-between">
    <div class="tab" [ngClass]="selectedTab === 'summary' ? 'selected' : ''" (click)="selectTab('summary')">Summary</div>
    <div class="tab" [ngClass]="selectedTab === 'scheduled' ? 'selected' : ''" (click)="selectTab('scheduled')">Scheduled</div>
    <div class="tab" [ngClass]="selectedTab === 'completed' ? 'selected' : ''" (click)="selectTab('completed')">Completed</div>
  </div>
  <app-e-force-summary *ngIf="selectedTab === 'summary'"></app-e-force-summary>
  <app-e-force-scheduled *ngIf="selectedTab === 'scheduled'"></app-e-force-scheduled>
  <app-e-force-completed *ngIf="selectedTab === 'completed'"></app-e-force-completed>
</div>
