<div class="search">
  <i class="fa fa-search"></i>
  <input type="text" placeholder="Search...">
</div>

<div class="item d-flex flex-row" *ngFor="let item of scheduled; let i = index">
  <div class="d-flex flex-column">
    <div class="date">
      <p class="month">{{item.date.month}}</p>
      <p class="day">{{item.date.day}}</p>
    </div>
    <div class="time">
      <p>{{item.time.getHours()}}:{{item.time.getMinutes() < 10 ? '0' + item.time.getMinutes() : item.time.getMinutes()}}</p>
      <p>PM</p>
    </div>
  </div>
  <div class="card d-flex flex-row">
    <div class="info">
      <div class="personal-info d-flex flex-row align-items-center">
        <div class="photo"></div>
        <p>{{item.name}}</p>
      </div>
      <div class="details d-flex flex-row">
        <div class="duration d-flex flex-column">
          <p>Duration</p>
          <p>{{item.duration}}</p>
        </div>
        <div class="estimate d-flex flex-column">
          <p>Estimate</p>
          <p>${{item.estimate}}</p>
        </div>
      </div>
    </div>
    <div class="options" >
      <i class="fa fa-ellipsis-v" (click)="openMenu(i)"></i>
      <div class="options-menu" [ngClass]="openMenuIndex === i ? 'open' : 'closed' ">
        <ul>
          <li>Edit</li>
          <li (click)="closeMenu(i)">Cancel</li>
        </ul>
      </div>
    </div>
  </div>
</div>
