import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-e-force-completed',
  templateUrl: './e-force-completed.component.html',
  styleUrls: ['./e-force-completed.component.scss']
})
export class EForceCompletedComponent implements OnInit {
  public openMenuIndex: number = -1;
  public completed = [
    {
      name: 'Riondo Jackson',
      date: { day: 27, month: 'Sep' },
      time: new Date(),
      hours: '0.5',
      total: '50'
    },
    {
      name: 'Jordan Statham',
      date: { day: 28, month: 'Sep' },
      time: new Date(),
      hours: '0.5',
      total: '100'
    },
    {
      name: 'Riondo Jackson',
      date: { day: 28, month: 'Sep' },
      time: new Date(),
      hours: '0.5',
      total: '100'
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

  openMenu(index: number) {
    this.openMenuIndex = index;
  }

  closeMenu(index: number) {
    this.openMenuIndex = -1;
  }

}
