import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AccessDoorsComponent} from "./screens/access-doors/access-doors.component";
import {AuthGuard} from "@auth0/auth0-angular";
import {EForceComponent} from "./screens/e-force/e-force.component";

const routes: Routes = [
  {
    path: '',
    component: AccessDoorsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'e-force',
    component: EForceComponent,
    canActivate: [AuthGuard],
  },
  {path: '**', redirectTo: 'login'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
