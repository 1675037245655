import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-e-force-scheduled',
  templateUrl: './e-force-scheduled.component.html',
  styleUrls: ['./e-force-scheduled.component.scss']
})
export class EForceScheduledComponent implements OnInit {
  public openMenuIndex: number = -1;
  public scheduled = [
    {
      name: 'Riondo Jackson',
      date: { day: 27, month: 'Sep' },
      time: new Date(),
      duration: '2 hours',
      estimate: '50'
    },
    {
      name: 'Jordan Statham',
      date: { day: 28, month: 'Sep' },
      time: new Date(),
      duration: '2 hours',
      estimate: '75'
    }
  ];
  constructor() { }

  ngOnInit(): void {
  }

  openMenu(index: number) {
    this.openMenuIndex = index;
  }

  closeMenu(index: number) {
    this.openMenuIndex = -1;
  }
}
