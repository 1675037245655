<div class=" main-menu">
  <div class="container">
    <!-- DESKTOP SCREEN SIZE MENU -->
<!--    <ul class="header-menu-desktop">-->
<!--      <li><img class="logo" src="assets/img/logo.svg" [routerLink]="['/access-doors']" routerLinkActive="active" alt="Saltbox OS"/></li>-->
<!--      <li><a [routerLink]="['/access-doors']" routerLinkActive="active">Elastic workforce</a></li>-->
<!--      <li><a [routerLink]="['/access-doors']" routerLinkActive="active">Flex Storage</a></li>-->
<!--      <li><a [routerLink]="['/access-doors']" routerLinkActive="active">Fulfillment</a></li>-->
<!--    </ul>-->
    <!-- END OF DESKTOP SCREEN SIZE MENU -->
    <!-- MOBILE SCREEN SIZE MENU -->
    <ul class="d-flex justify-content-between header-menu-mobile">
      <li><img class="logo" src="assets/img/logo.svg" [routerLink]="['/']" routerLinkActive="active" alt="Saltbox OS"/></li>
      <li>
        <button class="navbar-toggle" type="button" (click)="toggleMenu()">
          <img src="./assets/img/hamburger-menu.svg" alt="Menu">
        </button>
      </li>
    </ul>
    <div class="mobile-menu" [ngClass]="menuOpen ? 'open' : 'closed'">
      <div class="close-mobile-menu" (click)="toggleMenu()"></div>
      <div class="menu-canvas">
        <div class="user-info d-flex" *ngIf="auth.user$ | async as user">
          <div class="user-photo">
            <img src="{{user.picture}}" alt="img">
          </div>
          <div class="user-details">
            <p>{{ user.name }}</p>
            <p>{{ user.email }}</p>
            <p>Saltbox Inc.</p>
          </div>
        </div>
        <hr>
        <div class="flex-grow-1">
          <ul class="menu-items d-flex justify-content-between flex-column">
            <li class="featured"><a [routerLink]="['/']" routerLinkActive="active" (click)="toggleMenu()">Access Doors</a></li>
            <li class="featured">Discover Services</li>
            <li class="sub-item" ><a [routerLink]="['/e-force']" routerLinkActive="active" (click)="toggleMenu()">Elastic Workforce</a></li>
            <li class="sub-item"><a [routerLink]="['/access-doors']" routerLinkActive="active" (click)="toggleMenu()">Flex Storage</a></li>
            <li class="sub-item"><a [routerLink]="['/access-doors']" routerLinkActive="active" (click)="toggleMenu()">Fulfillment</a></li>
          </ul>
        </div>
        <hr>
        <ul class="logout">
          <li *ngIf="!(auth.isAuthenticated$ | async);"><a (click)="auth.loginWithRedirect()">Log in</a></li>
          <li *ngIf="auth.isAuthenticated$ | async;">
            <a (click)="auth.logout({ returnTo: 'http://localhost:4200/' })" class="d-flex">
              <img src="./assets/img/sign-out.svg" alt="img">
              <p>Sign out</p>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <!-- END OF MOBILE SCREEN SIZE MENU -->
  </div>
</div>
