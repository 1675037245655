<div class="discover-services-menu">
  <h3><span class="hand-drawn-underline">Discover services</span></h3>
  <div class="horizontal-scroll-elements">
    <div>
      <div class="service element">
        <img src='assets/img/people-icon.svg' />
      </div>
      <p class="service-name">Elastic workforce</p>
    </div>
    <div>
      <div class="service element">
        <img src='assets/img/storage-icon.svg' />
      </div>
      <p class="service-name">Flex storage</p>
    </div>
    <div>
      <div class="service element">
        <img src='assets/img/truck-icon.svg' />
      </div>
      <p class="service-name">Fulfillment</p>
    </div>
  </div>
</div>

