import {Injectable} from "@angular/core";
import {Apollo} from "apollo-angular";
import gql from "graphql-tag";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

const GET_USER_INFO_QUERY = gql `
  {
      me { name email company { name memberships { product { name } location { name } } } access { id name doors { id name lock { state } } } }
  }
`;

const GET_DOOR_INFO_QUERY = gql `
  {
      me { access { id name doors { id name lock { state } } } }
  }
`;

const OPEN_DOOR = gql `
  mutation UnlockDoor($id: ID) {
    unlockDoor(id: $id) {
      id lock { state }
    }
  }
`;

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  public locations: Observable<any> = new Observable<any>();

  public constructor(
    private apollo: Apollo
  ) {
  }

  public getUserInfo() {
    this.locations = this.apollo.watchQuery({
      query: GET_USER_INFO_QUERY,
    }).valueChanges.pipe(
      map((result: any) => {
        return result.data.me.access;
      })
    );
  }

  public getDoorInfo() {
    this.locations = this.apollo.watchQuery({
      query: GET_DOOR_INFO_QUERY,
      pollInterval: 3000,
    }).valueChanges.pipe(
      map((result: any) => {
        return result.data.me.access;
      })
    );
  }

  public openDoor(doorId: string) {
    this.apollo.mutate({
      mutation: OPEN_DOOR,
      variables: {
        id: doorId
      }
    }).subscribe(() => {
      console.log('success');
    });
  }

}
