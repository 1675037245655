import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-e-force',
  templateUrl: './e-force.component.html',
  styleUrls: ['./e-force.component.scss']
})
export class EForceComponent implements OnInit {
  public selectedTab: string = 'summary';

  constructor(
    private activatedRoute: ActivatedRoute,
    private readonly router: Router
  ) { }

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.queryParams.tab) {
      this.selectedTab = this.activatedRoute.snapshot.queryParams.tab;
    } else {
      this.selectedTab = 'summary';
    }

  }

  public selectTab(selectedTab: string) {
    this.selectedTab = selectedTab;
    this.router.navigate([], {
      queryParams: {
        tab: selectedTab
      }
    });
  }

}
