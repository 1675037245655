<div class="access-doors-page horizontal-scroll-container">
  <h3><span class="hand-drawn-underline">Access Doors</span></h3>
  <div class="horizontal-scroll-elements">
    <div class="lock element" *ngFor="let door of location.doors; let i = index" (click)="toggleDoor(door.id)" [ngClass]="door.lock.state === 'UNLOCKED' ? 'open' : ''">
      <img *ngIf="i < 2" class="star"
           [src]="door.lock.state === 'LOCKED' ? './assets/img/star-yellow-icon.svg' : './assets/img/star-white-icon.svg'"/>
      <img src='assets/img/{{ door.lock.state === "LOCKED" ? "lock-closed" : "lock-open" }}.svg' />
      <p class="door-name">{{door.name}}</p>
      <div class="door-status"><p>{{door.lock.state}}</p></div>
    </div>
  </div>
  <app-discover-services-menu></app-discover-services-menu>
</div>

