<div class="card d-flex flex-row">
  <img src="assets/icons/clock.svg" alt="Hours">
  <div class="content d-flex flex-column">
    <p class="content-heading">Hours This Month</p>
    <p class="content-value">0</p>
  </div>
</div>

<div class="card d-flex flex-row">
  <img src="assets/icons/calendar.svg" alt="Calendar">
  <div class=" content d-flex flex-column">
    <p class="content-heading">Next Scheduled</p>
    <p class="content-value">September 27, 2021 - 10am</p>
  </div>
</div>
