import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LoginComponent} from './screens/login/login.component';
import {RegisterComponent} from './screens/register/register.component';
import {ReactiveFormsModule} from "@angular/forms";
import {GraphQLModule} from './graphql.module';
import {HttpClientModule} from '@angular/common/http';
import {AccessDoorsComponent} from './screens/access-doors/access-doors.component';
import {MainMenuComponent} from './components/main-menu/main-menu.component';
import {AuthModule} from "@auth0/auth0-angular";
import {environment} from "../environments/environment";
import {HomeComponent} from "./screens/home/home.component";
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {AuthHttpInterceptor} from "@auth0/auth0-angular";
import { DiscoverServicesMenuComponent } from './components/discover-services-menu/discover-services-menu.component';
import { EForceComponent } from './screens/e-force/e-force.component';
import { EForceSummaryComponent } from './components/e-force/e-force-summary/e-force-summary.component';
import { EForceScheduledComponent } from './components/e-force/e-force-scheduled/e-force-scheduled.component';
import { EForceCompletedComponent } from './components/e-force/e-force-completed/e-force-completed.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    AccessDoorsComponent,
    MainMenuComponent,
    HomeComponent,
    DiscoverServicesMenuComponent,
    EForceComponent,
    EForceSummaryComponent,
    EForceScheduledComponent,
    EForceCompletedComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    GraphQLModule,
    HttpClientModule,
    AuthModule.forRoot({
      ...environment.auth,
      httpInterceptor: {
        allowedList: [`${environment.baseURL}`],
      },
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
