<div class="container">
  <div class="row">
    <form class="register-form" id="loginForm" [formGroup]="registerForm" (ngSubmit)="registerSubmit()">
      <div class="d-flex justify-content-between">
        <h2>Register</h2>
        <p>Already have an account? Login <a [routerLink]="['/login']">here</a></p>
      </div>
      <div class="form-group">
        <div class="d-flex justify-content-between">
          <label for="email">eMail</label>
        </div>
        <input class="form-control" type="text" id="email" formControlName="email">
      </div>
      <div class="form-group">
        <div class="d-flex justify-content-between">
          <label for="password">Password</label>
        </div>
        <input class="form-control" type="password" id="password" formControlName="password">
      </div>
      <div class="form-group">
        <div class="d-flex justify-content-between">
          <label for="passwordRepeat">Repeat Password</label>
        </div>
        <input class="form-control" type="password" id="passwordRepeat" formControlName="passwordRepeat">
      </div>
      <div class="d-flex justify-content-center">
        <button type="submit" class="btn btn-primary">Register</button>
      </div>
    </form>
  </div>
</div>

