import {Component, OnInit} from '@angular/core';
import {LocationService} from "../../services/location.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-location',
  templateUrl: './access-doors.component.html',
  styleUrls: ['./access-doors.component.scss']
})
export class AccessDoorsComponent implements OnInit {
  public location: any = {};

  constructor(
    private route: ActivatedRoute,
    public locationService: LocationService
  ) {
  }

  ngOnInit(): void {
    this.locationService.getUserInfo();
    this.locationService.getDoorInfo();
    this.location = this.locationService.locations.subscribe(locations => {
      this.location = locations[0];
    });
  }

  toggleDoor(doorId: string) {
    console.log(doorId);
    this.locationService.openDoor(doorId);
  }
}
