<div class="search">
  <i class="fa fa-search"></i>
  <input type="text" placeholder="Search...">
</div>

<div class="item d-flex flex-row" *ngFor="let item of completed; let i = index">
  <div class="d-flex flex-column">
    <div class="date">
      <p class="month">{{item.date.month}}</p>
      <p class="day">{{item.date.day}}</p>
    </div>
    <div class="time">
      <p>{{item.time.getHours()}}:{{item.time.getMinutes() < 10 ? '0' + item.time.getMinutes() : item.time.getMinutes()}}</p>
      <p>am</p>
    </div>
  </div>
  <div class="card d-flex flex-row">
    <div class="info">
      <div class="personal-info d-flex flex-row align-items-center">
        <div class="photo"></div>
        <p>{{item.name}}</p>
      </div>
      <div class="details d-flex flex-row">
        <div class="duration d-flex flex-column">
          <p>Hours</p>
          <p>{{item.hours}}</p>
        </div>
        <div class="total d-flex flex-column">
          <p>Total</p>
          <p>${{item.total}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
